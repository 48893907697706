<template>
  <div>
    <div v-if="label" class="label">{{ label }}</div>
    <div class="base-checkbox__wrapper">
      <label class="base-checkbox">
        <span class="base-checkbox__text">{{ text }}</span>
        <input
          type="checkbox"
          @change="$emit('input', $event.target.checked)"
        />
        <span class="base-checkbox__checkmark">
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 6L6 10L12 1" stroke="#3C4242" stroke-width="3" />
          </svg>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    label: String,
    text: String
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.base-checkbox__wrapper {
  margin-bottom: 15px;
}

.base-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ .base-checkbox__checkmark {
    background-color: $color-input-background;
  }

  input:checked ~ .base-checkbox__checkmark svg {
    opacity: 1;
  }
}

.base-checkbox__text {
  position: relative;
  top: 2px;
}

.base-checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $color-gray;
  transition: all 0.2s;

  svg {
    opacity: 0;
    position: absolute;
    top: 3px;
    left: 2px;
  }
}

.base-checkbox .base-checkbox__checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background: $color-font;
}
</style>
