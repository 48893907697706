var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors &&
        (!_vm.projectAddressData.isValid ||
          !_vm.projectPostalCodeData.isValid ||
          !_vm.projectTownData.isValid)
    }},[_c('BaseCheckbox',{attrs:{"label":"Prosjektadresse","text":"Samme som kontaktadresse"},on:{"input":_vm.copyAddress},model:{value:(_vm.isCheckedCopyAddress),callback:function ($$v) {_vm.isCheckedCopyAddress=$$v},expression:"isCheckedCopyAddress"}}),_c('BaseInput',{attrs:{"type":"text","placeholder":"Storgata 1","dataFromApi":_vm.projectAddressValue
          ? _vm.projectData.address === _vm.projectAddressValue
          : false},on:{"blur":_vm.getCoordinates,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validProjectAddress() : null}},model:{value:(_vm.projectAddressValue),callback:function ($$v) {_vm.projectAddressValue=$$v},expression:"projectAddressValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.projectAddressData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('BaseInput',{attrs:{"type":"number","placeholder":"Postnummer","dataFromApi":_vm.projectPostalCodeValue
          ? _vm.projectData.postalCode === _vm.projectPostalCodeValue
          : false},on:{"blur":_vm.getCoordinatesAndTown,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validProjectPostalCode() : null}},model:{value:(_vm.projectPostalCodeValue),callback:function ($$v) {_vm.projectPostalCodeValue=$$v},expression:"projectPostalCodeValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.projectPostalCodeData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('BaseInput',{attrs:{"type":"text","placeholder":"Sted","dataFromApi":_vm.projectTownValue ? _vm.projectData.town === _vm.projectTownValue : false},on:{"blur":_vm.getCoordinates,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validProjectTown() : null}},model:{value:(_vm.projectTownValue),callback:function ($$v) {_vm.projectTownValue=$$v},expression:"projectTownValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.projectTownData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('BaseSelect',{attrs:{"value":"Norge","disabled":""}})],1),_c('div',{staticClass:"step-group"},[_c('BaseMap',{attrs:{"label":"Tegn inn området du vil ha en rapport på"},on:{"input":_vm.getLandRegistry},model:{value:(_vm.coordinatesValue),callback:function ($$v) {_vm.coordinatesValue=$$v},expression:"coordinatesValue"}})],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors && !_vm.municipalityData.isValid
    }},[_c('BaseInput',{attrs:{"type":"text","label":"Kommune","placeholder":"Kommune","dataFromApi":_vm.municipalityValue
          ? _vm.projectData.municipality === _vm.municipalityValue
          : false},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validMunicipality() : null}},model:{value:(_vm.municipalityValue),callback:function ($$v) {_vm.municipalityValue=$$v},expression:"municipalityValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.municipalityData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error': _vm.stepValidationValue.showErrors && !_vm.gnrData.isValid
    }},[_c('BaseInput',{attrs:{"type":"number","label":"Gårdsnummer","placeholder":"12","dataFromApi":_vm.gnrValue ? _vm.projectData.gnr === _vm.gnrValue : false},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validGnr() : null}},model:{value:(_vm.gnrValue),callback:function ($$v) {_vm.gnrValue=$$v},expression:"gnrValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.gnrData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error': _vm.stepValidationValue.showErrors && !_vm.bnrData.isValid
    }},[_c('BaseInput',{attrs:{"type":"number","label":"Bruksnummer","placeholder":"34","dataFromApi":_vm.bnrValue ? _vm.projectData.bnr === _vm.bnrValue : false},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validBnr() : null}},model:{value:(_vm.bnrValue),callback:function ($$v) {_vm.bnrValue=$$v},expression:"bnrValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.bnrData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors && !_vm.projectTypesData.isValid
    }},[_c('BaseTreeSelect',{attrs:{"label":"Prosjekttype","items":_vm.projectTypes},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validProjectTypes() : null}},model:{value:(_vm.projectTypesValue),callback:function ($$v) {_vm.projectTypesValue=$$v},expression:"projectTypesValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.projectTypesData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors && !_vm.projectDescriptionData.isValid
    }},[_c('BaseTextarea',{attrs:{"label":"Beskrivelse av prosjektet","placeholder":"F.eks. Etablering av boligblokk i 4 plan med 2 kjelleretasjer"},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validProjectDescription() : null}},model:{value:(_vm.projectDescriptionValue),callback:function ($$v) {_vm.projectDescriptionValue=$$v},expression:"projectDescriptionValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.projectDescriptionData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group"},[_c('BaseFileUpload',{attrs:{"label":"Prosjekttegning (valgfritt)<br>*.dwg, *dxf, *ifc","accept":".dwg, .dxf, .ifc"},model:{value:(_vm.projectDrawingValue),callback:function ($$v) {_vm.projectDrawingValue=$$v},expression:"projectDrawingValue"}})],1),_c('div',{staticClass:"step-group"},[_c('BaseInput',{attrs:{"type":"text","label":"Prosjektnavn (valgfritt)","placeholder":"e.g. Sameiet Verftshage"},model:{value:(_vm.projectNameValue),callback:function ($$v) {_vm.projectNameValue=$$v},expression:"projectNameValue"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }