<template>
  <div>
    <div v-if="label" class="label">{{ label }}</div>
    <v-treeview
      v-if="items.length"
      dense
      :value="value"
      :items="items"
      selection-type="leaf"
      selectable
      open-on-click
      @input="updateValue"
    />
  </div>
</template>

<script>
export default {
  name: "BaseTreeSelect",
  props: {
    label: String,
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/scss/variables";

// Override Vuetify's TreeView component styles
// It cannot be scoped so there are used nested styles
.v-treeview-node__root {
  padding: 0;
  margin-left: -15px;

  .v-treeview-node__toggle {
    color: $color-font !important;

    &:after {
      display: none !important;
    }
  }

  .v-treeview-node__toggle--open {
    transform: rotate(-90deg);
  }

  .v-treeview-node__checkbox {
    margin-right: 10px;

    &:after {
      display: none !important;
    }
  }

  .mdi-checkbox-blank-outline::before,
  .mdi-checkbox-marked::before,
  .mdi-minus-box::before {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid $color-gray;
  }

  .mdi-checkbox-blank-outline::before {
    content: "";
    background-color: white;
    transition: all 0.2s;
  }

  .mdi-checkbox-marked::before {
    color: $color-font;
    content: "\2714";
    font-size: 14px;
    padding: 2px;
    background-color: $color-primary-light;
  }

  .mdi-minus-box::before {
    color: $color-font;
    content: "\2013";
    font-size: 16px;
    font-weight: bold;
    background-color: $color-primary-light;
  }
}
</style>
