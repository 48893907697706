<template>
  <div>
    <div v-if="label" class="label">{{ label }}</div>
    <vl-map
      ref="map"
      style="height: 400px"
      :load-tiles-while-animating="true"
      :load-tiles-while-interacting="true"
      data-projection="EPSG:25833"
      @mounted="initMap"
    >
      <vl-view
        projection="EPSG:25833"
        rotation.sync="0"
        :zoom.sync="zoom"
        :center.sync="center"
        :max-resolution="21664"
        :min-resolution="0.020660400390625"
      />

      <vl-layer-tile id="wmts">
        <vl-source-wmts
          attributions="Kartverket"
          url="https://opencache.statkart.no/gatekeeper/gk/gk.open_wmts?"
          format="image/png"
          layer-name="norgeskart_bakgrunn"
          style-name="default"
          projection="EPSG:25833"
          matrix-set="EPSG:25833"
          :origin="origin"
          :resolutions="resolutions"
          :matrix-ids="matrixIds"
        />
      </vl-layer-tile>

      <vl-layer-vector :z-index="1">
        <vl-source-vector
          :features.sync="polygonSettings"
          ident="source"
        ></vl-source-vector>

        <vl-style-box>
          <vl-style-stroke color="green"></vl-style-stroke>
          <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
        </vl-style-box>
      </vl-layer-vector>

      <vl-interaction-draw
        :active="polygonSettings.length===0"
        type="Polygon"
        source="source"
        :min-points="3"
        @drawend="polygonModify"
      >
        <vl-style-box>
          <vl-style-stroke color="blue"></vl-style-stroke>
          <vl-style-fill color="rgba(255,255,255,0.5)"></vl-style-fill>
        </vl-style-box>
      </vl-interaction-draw>
      <vl-interaction-modify
        source="source"
        @modifyend="polygonModify"
      ></vl-interaction-modify>
    </vl-map>
  </div>
</template>

<script>
import Projection from "ol/proj/Projection";
import { getWidth, getTopLeft } from "ol/extent";
import { transform } from "ol/proj";
import { buffer, boundingExtent } from "ol/extent";

const defaultCoordinates = {
  epsg: "EPSG:25833",
  lat: 407814,
  lon: 6957769
};

export default {
  name: "BaseMap",
  props: {
    label: String,
    value: {}
  },
  data() {
    return {
      polygonCoordinates: [],
      polygonSettings: [],
      center: [defaultCoordinates.lat, defaultCoordinates.lon],
      zoom: 2,
      rotation: 0,
      resolutions: [],
      matrixIds: [],
      origin: null
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.center = transform([val.lon, val.lat], val.epsg, "EPSG:25833");
        this.zoom = 17;
      } else {
        this.center = [defaultCoordinates.lat, defaultCoordinates.lon];
        this.zoom = 2;
      }
      this.initMap();
      this.calcPolygonCoordinates();
    },
    polygonCoordinates(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    polygonModify(event) {
      (event.features || [event.feature]).forEach(feature => {
        this.$emit("input", feature.getGeometry().getCoordinates());
      });
    },
    initMap() {
      this.$refs.map.$map.getView().setCenter(this.center);
      var projection = new Projection({
        code: "EPSG:25833",
        extent: [-2500000, 3500000, 3045984, 9045984]
      });
      var resolutions = [];
      var matrixIds = [];
      var size = getWidth(projection.getExtent()) / 256;
      for (var z = 0; z < 21; ++z) {
        resolutions[z] = size / Math.pow(2, z);
        var matrixId = "EPSG:25833" + ":" + z;
        matrixIds[z] = matrixId;
      }
      this.origin = getTopLeft(projection.getExtent());
      this.resolutions = resolutions;
      this.matrixIds = matrixIds;
    },
    calcPolygonCoordinates() {
      const settings = {
        type: "Feature",
        id: "polygon-id",
        geometry: { type: "Polygon", coordinates: [] },
        properties: null
      };

      if (this.value) {
        const transformed = transform(
          [this.value.lon, this.value.lat],
          this.value.epsg,
          "EPSG:25833"
        );
        const extent1 = boundingExtent([transformed]);
        const buffer1 = buffer(extent1, 5);
        const output = [
          [buffer1[0], buffer1[1]],
          [buffer1[0], buffer1[3]],
          [buffer1[2], buffer1[3]],
          [buffer1[2], buffer1[1]],
          [buffer1[0], buffer1[1]]
        ];
        settings.geometry.coordinates = [output];
        this.polygonCoordinates = [output];
      } else {
        settings.geometry.coordinates = [];
        this.polygonCoordinates = [];
      }

      this.polygonSettings = [settings];
    }
  }
};
</script>

<style lang="scss" scoped></style>
