<template>
  <div>
    <div v-if="label" class="label" v-html="label"></div>
    <div class="base-file-upload__wrapper">
      <input
        type="file"
        id="base-file-upload"
        class="base-file-upload"
        :accept="accept"
        :multiple="multiple"
        @change="selectFile"
      />
      <label for="base-file-upload" class="base-file-upload__btn">
        <div class="btn__icon">
          <!-- eslint-disable-next-line -->
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- eslint-disable-next-line -->
            <path d="M13 5.5H8.5V1C8.5 0.447812 8.05219 0 7.5 0H6.5C5.94781 0 5.5 0.447812 5.5 1V5.5H1C0.447813 5.5 0 5.94781 0 6.5V7.5C0 8.05219 0.447813 8.5 1 8.5H5.5V13C5.5 13.5522 5.94781 14 6.5 14H7.5C8.05219 14 8.5 13.5522 8.5 13V8.5H13C13.5522 8.5 14 8.05219 14 7.5V6.5C14 5.94781 13.5522 5.5 13 5.5Z" fill="#40D129"/>
          </svg>
        </div>
        <div class="btn__title">
          <template v-if="multiple">Velg filer</template>
          <template v-else>Velg fil</template>
        </div>
      </label>
      <div class="file-list">
        <p
          class="file-list-item"
          v-for="(file, index) in chosenFiles"
          :key="index"
        >
          &middot; {{ file.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFileUpload",
  data() {
    return {
      chosenFiles: []
    };
  },
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    accept: String,
    multiple: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  methods: {
    selectFile(e) {
      this.chosenFiles = [];
      for (let i = 1; i <= e.target.files.length; i++) {
        this.chosenFiles.push(e.target.files[i - 1]);
      }
      this.$emit("input", this.chosenFiles);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.base-file-upload {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.base-file-upload__btn {
  display: block;
  position: relative;
  width: 180px;
  height: 30px;
  border: 2px solid $color-primary;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;

  .btn__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-right: 2px solid $color-primary;
  }

  .btn__title {
    color: $color-primary;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }
}

input:hover + label,
input:focus + label {
  transform: scale(1.02);
}

.file-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.file-list-item {
  display: inline-block;
  background-color: lighten($color-primary, 45%);
  font-size: 14px;
  margin: 5px 0;
  padding: 8px 16px;
  border-radius: 4px;
}
</style>
