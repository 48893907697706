<template>
  <div>
    <div v-if="label" class="label">{{ label }}</div>
    <div class="base-textarea__wrapper">
      <textarea
        class="base-textarea"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    value: String
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.base-textarea {
  border: 1px solid $color-input-border;
  border-radius: 4px;
  background-color: $color-input-background;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 12px;
  min-height: 168px;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  transition: all 0.2s;

  &::placeholder {
    color: $color-input-placeholder;
  }

  &:focus {
    outline: none;
    border-color: $color-primary;
    box-shadow: inset 0 0 0 1px $color-primary;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
