import { render, staticRenderFns } from "./BaseTextarea.vue?vue&type=template&id=205b1d92&scoped=true&"
import script from "./BaseTextarea.vue?vue&type=script&lang=js&"
export * from "./BaseTextarea.vue?vue&type=script&lang=js&"
import style0 from "./BaseTextarea.vue?vue&type=style&index=0&id=205b1d92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205b1d92",
  null
  
)

export default component.exports