<template>
  <div>
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors &&
          (!projectAddressData.isValid ||
            !projectPostalCodeData.isValid ||
            !projectTownData.isValid)
      }"
    >
      <BaseCheckbox
        label="Prosjektadresse"
        text="Samme som kontaktadresse"
        v-model="isCheckedCopyAddress"
        @input="copyAddress"
      />

      <!-- Project Address -->
      <BaseInput
        type="text"
        placeholder="Storgata 1"
        v-model="projectAddressValue"
        :dataFromApi="
          projectAddressValue
            ? projectData.address === projectAddressValue
            : false
        "
        @blur="getCoordinates"
        @input="stepValidationValue.showErrors ? validProjectAddress() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in projectAddressData.errors" :key="index">
          {{ error }}
        </div>
      </div>

      <!-- Project Postal Code -->
      <BaseInput
        type="number"
        placeholder="Postnummer"
        v-model="projectPostalCodeValue"
        :dataFromApi="
          projectPostalCodeValue
            ? projectData.postalCode === projectPostalCodeValue
            : false
        "
        @blur="getCoordinatesAndTown"
        @input="
          stepValidationValue.showErrors ? validProjectPostalCode() : null
        "
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div
          v-for="(error, index) in projectPostalCodeData.errors"
          :key="index"
        >
          {{ error }}
        </div>
      </div>

      <!-- Project Town -->
      <BaseInput
        type="text"
        placeholder="Sted"
        v-model="projectTownValue"
        :dataFromApi="
          projectTownValue ? projectData.town === projectTownValue : false
        "
        @blur="getCoordinates"
        @input="stepValidationValue.showErrors ? validProjectTown() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in projectTownData.errors" :key="index">
          {{ error }}
        </div>
      </div>

      <!-- Project Country -->
      <BaseSelect value="Norge" disabled />
    </div>

    <!-- Location -->
    <div class="step-group">
      <BaseMap
        label="Tegn inn området du vil ha en rapport på"
        v-model="coordinatesValue"
        @input="getLandRegistry"
      />
    </div>

    <!-- Municipality -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !municipalityData.isValid
      }"
    >
      <BaseInput
        type="text"
        label="Kommune"
        placeholder="Kommune"
        v-model="municipalityValue"
        :dataFromApi="
          municipalityValue
            ? projectData.municipality === municipalityValue
            : false
        "
        @input="stepValidationValue.showErrors ? validMunicipality() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in municipalityData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Gårdsnumber -->
    <div
      class="step-group"
      :class="{
        'step-group--error': stepValidationValue.showErrors && !gnrData.isValid
      }"
    >
      <BaseInput
        type="number"
        label="Gårdsnummer"
        placeholder="12"
        v-model="gnrValue"
        :dataFromApi="gnrValue ? projectData.gnr === gnrValue : false"
        @input="stepValidationValue.showErrors ? validGnr() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in gnrData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Bruksnummer -->
    <div
      class="step-group"
      :class="{
        'step-group--error': stepValidationValue.showErrors && !bnrData.isValid
      }"
    >
      <BaseInput
        type="number"
        label="Bruksnummer"
        placeholder="34"
        v-model="bnrValue"
        :dataFromApi="bnrValue ? projectData.bnr === bnrValue : false"
        @input="stepValidationValue.showErrors ? validBnr() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in bnrData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Project types -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !projectTypesData.isValid
      }"
    >
      <BaseTreeSelect
        label="Prosjekttype"
        :items="projectTypes"
        v-model="projectTypesValue"
        @input="stepValidationValue.showErrors ? validProjectTypes() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in projectTypesData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Project description -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !projectDescriptionData.isValid
      }"
    >
      <BaseTextarea
        label="Beskrivelse av prosjektet"
        placeholder="F.eks. Etablering av boligblokk i 4 plan med 2 kjelleretasjer"
        v-model="projectDescriptionValue"
        @input="
          stepValidationValue.showErrors ? validProjectDescription() : null
        "
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div
          v-for="(error, index) in projectDescriptionData.errors"
          :key="index"
        >
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Project drawing -->
    <div class="step-group">
      <BaseFileUpload
        label="Prosjekttegning (valgfritt)<br>*.dwg, *dxf, *ifc"
        accept=".dwg, .dxf, .ifc"
        v-model="projectDrawingValue"
      />
    </div>

    <!-- Project name -->
    <div class="step-group">
      <BaseInput
        type="text"
        label="Prosjektnavn (valgfritt)"
        placeholder="e.g. Sameiet Verftshage"
        v-model="projectNameValue"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { Polygon } from "ol/geom";

import BaseCheckbox from "../components/BaseCheckbox";
import BaseFileUpload from "../components/BaseFileUpload";
import BaseInput from "../components/BaseInput";
import BaseMap from "../components/BaseMap";
import BaseSelect from "../components/BaseSelect";
import BaseTextarea from "../components/BaseTextarea";
import BaseTreeSelect from "../components/BaseTreeSelect";

export default {
  components: {
    BaseCheckbox,
    BaseFileUpload,
    BaseInput,
    BaseMap,
    BaseSelect,
    BaseTextarea,
    BaseTreeSelect
  },
  data() {
    return {
      isCheckedCopyAddress: false,
      projectData: {},
      projectTypes: []
    };
  },
  computed: {
    isAddressFilled() {
      return (
        this.projectAddressValue &&
        this.projectPostalCodeValue &&
        this.projectTownValue
      );
    },

    stepValidationValue() {
      return this.$store.state.step2.stepValidation;
    },

    projectAddressData() {
      return this.$store.state.step2.projectAddress;
    },
    projectAddressValue: {
      get() {
        return this.$store.state.step2.projectAddress.value;
      },
      set(value) {
        this.$store.commit("step2/updateProjectAddress", value);
      }
    },

    projectPostalCodeData() {
      return this.$store.state.step2.projectPostalCode;
    },
    projectPostalCodeValue: {
      get() {
        return this.$store.state.step2.projectPostalCode.value;
      },
      set(value) {
        this.$store.commit("step2/updateProjectPostalCode", value);
      }
    },

    projectTownData() {
      return this.$store.state.step2.projectTown;
    },
    projectTownValue: {
      get() {
        return this.$store.state.step2.projectTown.value;
      },
      set(value) {
        this.$store.commit("step2/updateProjectTown", value);
      }
    },

    coordinatesValue: {
      get() {
        return this.$store.state.step2.coordinates.value;
      },
      set(value) {
        this.$store.commit("step2/updateCoordinates", value);
      }
    },

    municipalityData() {
      return this.$store.state.step2.municipality;
    },
    municipalityValue: {
      get() {
        return this.$store.state.step2.municipality.value;
      },
      set(value) {
        this.$store.commit("step2/updateMunicipality", value);
      }
    },

    gnrData() {
      return this.$store.state.step2.gnr;
    },
    gnrValue: {
      get() {
        return this.$store.state.step2.gnr.value;
      },
      set(value) {
        this.$store.commit("step2/updateGnr", value);
      }
    },

    bnrData() {
      return this.$store.state.step2.bnr;
    },
    bnrValue: {
      get() {
        return this.$store.state.step2.bnr.value;
      },
      set(value) {
        this.$store.commit("step2/updateBnr", value);
      }
    },

    projectTypesData() {
      return this.$store.state.step2.projectTypes;
    },
    projectTypesValue: {
      get() {
        return this.$store.state.step2.projectTypes.value;
      },
      set(value) {
        this.$store.commit("step2/updateProjectTypes", value);
      }
    },

    projectDescriptionData() {
      return this.$store.state.step2.projectDescription;
    },
    projectDescriptionValue: {
      get() {
        return this.$store.state.step2.projectDescription.value;
      },
      set(value) {
        this.$store.commit("step2/updateProjectDescription", value);
      }
    },

    projectDrawingValue: {
      get() {
        return this.$store.state.step2.projectDrawing.value;
      },
      set(value) {
        this.$store.commit("step2/updateProjectDrawing", value);
      }
    },

    projectNameValue: {
      get() {
        return this.$store.state.step2.projectName.value;
      },
      set(value) {
        this.$store.commit("step2/updateProjectName", value);
      }
    }
  },
  methods: {
    ...mapMutations("step2", [
      "validProjectAddress",
      "validProjectPostalCode",
      "validProjectTown",
      "validMunicipality",
      "validGnr",
      "validBnr",
      "validProjectTypes",
      "validProjectDescription"
    ]),
    copyAddress() {
      if (this.isCheckedCopyAddress) {
        this.$store.commit(
          "step2/updateProjectAddress",
          this.$store.state.step1.address.value
        );
        this.projectData.address = this.$store.state.step1.address.value;

        this.$store.commit(
          "step2/updateProjectPostalCode",
          this.$store.state.step1.postalCode.value
        );
        this.projectData.postalCode = this.$store.state.step1.postalCode.value;

        this.$store.commit(
          "step2/updateProjectTown",
          this.$store.state.step1.town.value
        );
        this.projectData.town = this.$store.state.step1.town.value;

        if (this.isAddressFilled) {
          this.getCoordinates();
        } else {
          this.$store.commit("step2/resetCoordinates");
        }
      } else {
        this.$store.commit("step2/updateProjectAddress", "");
        this.$store.commit("step2/updateProjectPostalCode", "");
        this.$store.commit("step2/updateProjectTown", "");
        this.$store.commit("step2/resetCoordinates");
      }
    },
    getCoordinates() {
      if (this.projectAddressValue) {
        axios
          .get("https://ws.geonorge.no/adresser/v1/sok", {
            params: {
              adressetekst: this.projectAddressValue,
              postnummer: this.projectPostalCodeValue,
              kommunenavn: this.projectTownValue
            }
          })
          .then(response => {
            if (
              response.data.adresser[0] &&
              response.data.adresser[0].representasjonspunkt
            ) {
              this.$store.commit(
                "step2/updateCoordinates",
                response.data.adresser[0].representasjonspunkt
              );

              this.getLandRegistry();
            } else {
              this.$store.commit("step2/updateCoordinates", null);
            }
          });
      }
    },
    getLandRegistry() {
      let params = {
        lat: this.coordinatesValue.lat,
        lon: this.coordinatesValue.lon,
        radius: 300
      };
      if (!params.lat) {
        params.koordsys = 25833;
        let polygon = new Polygon(this.coordinatesValue);
        params.lat = polygon.getInteriorPoint().getCoordinates()[1];
        params.lon = polygon.getInteriorPoint().getCoordinates()[0];
      }
      axios
        .get("https://ws.geonorge.no/adresser/v1/punktsok", {
          params: params
        })
        .then(response => {
          const data = response.data.adresser[0];
          this.$store.commit("step2/updateMunicipality", data.kommunenavn);
          this.$store.commit("step2/updateGnr", data.gardsnummer.toString());
          this.$store.commit("step2/updateBnr", data.bruksnummer.toString());

          this.projectData.municipality = data.kommunenavn;
          this.projectData.gnr = data.gardsnummer.toString();
          this.projectData.bnr = data.bruksnummer.toString();

          if (!this.isAddressFilled) {
            this.$store.commit("step2/updateProjectAddress", data.adressetekst);
            this.$store.commit(
              "step2/updateProjectPostalCode",
              data.postnummer
            );
            this.$store.commit("step2/updateProjectTown", data.poststed);
            this.projectData.address = data.adressetekst;
            this.projectData.postalCode = data.postnummer;
            this.projectData.town = data.poststed;
          }
        });
    },
    getTown() {
      axios
        .get("https://ws.geonorge.no/adresser/v1/sok", {
          params: {
            postnummer: this.projectPostalCodeValue
          }
        })
        .then(response => {
          const data = response.data.adresser[0];
          this.$store.commit("step2/updateProjectTown", data.poststed);

          this.projectData.town = data.poststed;
        });
    },
    getCoordinatesAndTown() {
      this.getTown();
      this.getCoordinates();
    },
    getProjectTypes() {
      axios
        .get(`${process.env.VUE_APP_CONTACT_FORM_API_URL}project_types/`)
        .then(response => {
          this.projectTypes = response.data;
        });
    }
  },
  beforeCreate() {
    if (!this.$store.state.step1.stepValidation.isValid) {
      this.$router.push({ name: "Step-1" });
    }
  },
  mounted() {
    this.getProjectTypes();
  }
};
</script>
